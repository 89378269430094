import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import { Layout, DownloadPdf, SEO } from '../components'

const SupportTechniquePage = ({ data }) => {
  const { Moteurs, Accessoires, Ferrures, Portes } = data

  return (
    <Layout>
      <SEO title="Support technique" description="" />
      <h1 className="title">Support technique</h1>
      <section className="bg-gray-300 py-6 text-center mb-4 shadow-lg">
        <h2>Portes</h2>
        <div className="flex flex-wrap justify-around">
          {Portes.edges.length > 0 &&
            Portes.edges.map(({ node }, index) => (
              <div key={index}>
                <h2>{node.name}</h2>
                <DownloadPdf url={node.publicURL} name={node.name}>
                  Télécharger le manuel
                </DownloadPdf>
              </div>
            ))}
        </div>
      </section>
      <section className="bg-gray-400 py-6 text-center mb-4 shadow-lg">
        <h2>Notice de montage pour porte sectionnelle</h2>
        <div className="flex flex-wrap justify-around">
          {Ferrures.edges.length > 0 &&
            Ferrures.edges.map(({ node }, index) => (
              <div key={index}>
                <h2>{node.name}</h2>
                <DownloadPdf url={node.publicURL} name={node.name}>
                  Télécharger le manuel
                </DownloadPdf>
              </div>
            ))}
        </div>
      </section>
      <section className="bg-gray-300 py-6 text-center mb-4 shadow-lg">
        <h2>Moteurs</h2>
        <div className="flex flex-wrap justify-around">
          {Moteurs.edges.length > 0 &&
            Moteurs.edges.map(({ node }, index) => (
              <div key={index}>
                <h2>{node.name}</h2>
                <DownloadPdf url={node.publicURL} name={node.name}>
                  Télécharger le manuel
                </DownloadPdf>
              </div>
            ))}
        </div>
      </section>
      <section className="bg-gray-400 py-6 text-center shadow-lg">
        <h2>Accessoires</h2>
        <div className="flex flex-wrap justify-around">
          {Accessoires.edges.length > 0 &&
            Accessoires.edges.map(({ node }, index) => (
              <div key={index}>
                <h2>{node.name}</h2>
                <DownloadPdf url={node.publicURL} name={node.name}>
                  Télécharger le manuel
                </DownloadPdf>
              </div>
            ))}
        </div>
      </section>
    </Layout>
  )
}

SupportTechniquePage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default SupportTechniquePage

export const pageQuery = graphql`
  query SupportTechnique {
    Moteurs: allFile(
      filter: {
        extension: { eq: "pdf" }
        relativePath: { regex: "/support-technique/Moteur*/" }
      }
    ) {
      edges {
        node {
          name
          publicURL
        }
      }
    }
    Accessoires: allFile(
      filter: {
        extension: { eq: "pdf" }
        relativePath: { regex: "/support-technique/Accessoire*/" }
      }
    ) {
      edges {
        node {
          name
          publicURL
        }
      }
    }
    Ferrures: allFile(
      filter: {
        extension: { eq: "pdf" }
        relativePath: { regex: "/support-technique/Ferrure*/" }
      }
    ) {
      edges {
        node {
          name
          publicURL
        }
      }
    }
    Portes: allFile(
      filter: {
        extension: { eq: "pdf" }
        relativePath: { regex: "/support-technique/Porte*/" }
      }
    ) {
      edges {
        node {
          name
          publicURL
        }
      }
    }
  }
`
